import React from 'react';
import headerImage from '../images/header.jpg';

const Header = () => {
    return (
      <header style={{ position: 'relative', width: '100%', height: '150px' }}> {/* Adjust height as needed */}
        <img src={headerImage} alt="Header" style={{ width: '100%', height: '100%' }} />
        <h1 style={{
          position: 'absolute',
          top: '10%', // Centers vertically
          left: '50%', // Centers horizontally
          transform: 'translate(-50%, -50%)', // Ensures centering
          color: 'black' // Example text color, change as needed
        }}>
          WayneRockett.Com
        </h1>
      </header>
    );
  };

export default Header;
